<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
    >
      <!-- General Information -->
      <base-material-card
        icon="mdi-numeric"
        color="secondary"
        title="Policy Information"
      >
        <v-form
          ref="generalForm"
          lazy-validation
          class="px-2"
          @submit.prevent="updateGeneral"
        >
          <v-select
            v-model="policy.policy_type_id"
            label="Policy Type"
            :items="mixinItems.policyTypes"
            item-text="name"
            item-value="id"
            :loading="loadingMixins.policyTypes"
          />
          <v-select
            v-if="policy.policy_type_id === packagePolicyType"
            v-model="policy.package_type_id"
            label="Package Type"
            :items="mixinItems.packageTypes"
            item-text="name"
            item-value="id"
            :loading="loadingMixins.packageTypes"
          />
          <v-text-field
            v-model="policy.policy_number"
            label="Policy Number"
            clearable
            :rules="[required]"
          />
          <v-text-field
            v-model="policy.binder_number"
            label="Binder Number"
            clearable
          />
          <v-select
            v-model="policy.term_id"
            label="Term"
            :items="mixinItems.terms"
            item-text="name"
            item-value="id"
            :loading="loadingMixins.terms"
          />
          <v-select
            v-model="policy.finance_option_id"
            label="Finance Option"
            :items="mixinItems.financeOptions"
            item-text="name"
            item-value="id"
            :loading="loadingMixins.financeOptions"
          />
          <v-select
            v-model="policy.billing_type_id"
            label="Billing Type"
            :items="mixinItems.billingTypes"
            item-text="name"
            item-value="id"
            :loading="loadingMixins.billingTypes"
          />
          <v-select
            v-model="policy.premium_sent_id"
            label="Premium Sent"
            :items="mixinItems.premiumSents"
            item-text="name"
            item-value="id"
            :loading="loadingMixins.premiumSents"
          />

          <div class="text-right">
            <v-btn
              small
              type="submit"
              color="success"
              :loading="updatingGeneral"
            >
              <v-icon left>
                mdi-update
              </v-icon>
              Update
            </v-btn>
            <v-btn
              small
              color="error"
              :loading="deletingPolicy"
              @click="deletePolicy"
            >
              <v-icon left>
                mdi-delete
              </v-icon>
              Delete
            </v-btn>
          </div>
        </v-form>
      </base-material-card>
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      <!-- Line of Businesses -->
      <base-material-card
        v-if="policy.policy_type_id === packagePolicyType"
        icon="mdi-umbrella"
        color="success"
        title="Line of Businesses"
      >
        <v-form
          ref="businessLineForm"
          lazy-validation
          @submit.prevent="updateBusinessLine"
        >
          <v-select
            v-model="policy.line_of_business_ids"
            label="Line of Business"
            :items="mixinItems.businessLines"
            item-value="id"
            item-text="name"
            clearable
            multiple
            :loading="loadingMixins.businessLines"
            :rules="[arrayRequired]"
          />

          <div class="text-right">
            <v-btn
              small
              type="submit"
              color="success"
              :loading="updatingBusinessLine"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              Update
            </v-btn>
          </div>
        </v-form>
      </base-material-card>

      <!-- Important Dates -->
      <base-material-card
        color="warning"
        icon="mdi-calendar-alert"
        title="Important Dates"
      >
        <policy-form :policy="policy" />
      </base-material-card>
    </v-col>
  </v-row>
</template>

<script>
  import { Api } from '@/apis'
  import { fetchInitials } from '@/mixins/fetchInitials'
  import { packagePolicyType, MIXINS } from '@/constants'

  export default {
    components: {
      PolicyForm: () => import('../../components/policy/PolicyForm.vue'),
    },

    mixins: [
      fetchInitials([
        MIXINS.businessLines,
        MIXINS.policyTypes,
        MIXINS.packageTypes,
        MIXINS.terms,
        MIXINS.financeOptions,
        MIXINS.billingTypes,
        MIXINS.premiumSents,
      ]),
    ],

    props: {
      policy: {
        type: Object,
        default: () => ({}),
      },
    },

    data: () => ({
      required: value => !!value || 'This field is required.',
      arrayRequired: value => !!value.length || 'This field is required',
      updatingGeneral: false,
      deletingPolicy: false,
      updatingBusinessLine: false,
      packagePolicyType,
    }),

    mounted () {
      if (this.policy && Array.isArray(this.policy.line_of_businesses)) {
        this.policy.line_of_business_ids = this.policy.line_of_businesses.map(business => business.line_of_business_id)
      }
    },

    methods: {
      async updateBusinessLine () {
        if (this.$refs.businessLineForm.validate()) {
          this.updatingBusinessLine = true
          await new Api().put({
            url: 'policies/policy-line-of-businesses/' + this.$route.params.id,
            request: { line_of_businesses: this.policy.line_of_business_ids },
          })
          this.updatingBusinessLine = false
        }
      },

      async updateGeneral () {
        if (this.$refs.generalForm.validate()) {
          this.updatingGeneral = true
          await new Api().put({
            url: 'policies/policy/' + this.$route.params.id,
            request: this.policy,
          })
          this.updatingGeneral = false
        }
      },

      async deletePolicy () {
        const decision = await this.$confirm('Are you sure you want to delete?', { title: 'Warning' })
        if (decision) {
          this.deletingPolicy = true
          await new Api().delete('policies/policy/' + this.$route.params.id)
          this.deletingPolicy = false
        }
      },
    },
  }
</script>
